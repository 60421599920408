<template>
  <div class="building-video">
    <div class="video-top">
      <div class="top-icon" @click.stop="goBack">
        <van-icon name="arrow-left" />
      </div>
      <div v-if="topVideo.id"  @click="goVideoDetail(topVideo.id)">
        <div class="top-name">{{topVideo.title}}</div>
        <div class="top-img">
          <img :src="topVideo.pic" alt="">
        </div>
      </div>
      <div class="video-content" v-if="videoList && videoList.length > 0">
        <div class="video-box" v-for="item in videoList" :key="item.id">
          <div class="video-category">{{item.catName}}</div>
          <div class="video-msg">
            <div class="video-item" @click="goVideoDetail(item01.id)" v-for="(item01,index01) in item.houseVideos" :key="index01">
              <img :src="item01.pic" alt="" />
              <div class="video-text">
                <span>{{ item01.title }}</span>
                <p>{{ item01.playCount }}次播放</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <van-empty v-else description="暂无视频" />

    </div>
  </div>
</template>
<script>
export default {
  name: "buildingVideo",
  data() {
    return {
      topVideo: {},
      videoList: [],
    };
  },
  created() {
    // 获取传递过来的id
    this.id = this.$route.query.id;
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    goVideoDetail(id) {
    sessionStorage.setItem("videoType",true)
      this.$router.push({
        path: "/videoDetail",
        query: {
          id: id,
          houseid: this.id,
        },
      });
    },
    getHouseVideoList() {
      const data = {
        //楼盘id
        id: this.id,
      };

      this.$post("houseVideo/getHouseVideoList", data).then((res) => {
        if (res.status == 200) {
          // this.typeList = res.data.houseTypes
          this.videoList = res.data.houseVideoList;
          console.log(res.data.houseVideoList)
          this.topVideo = res.data.firstVideo;
        }else {
          this.$toast.fail(res.msg);
        }
      });
    },
  },
  mounted() {
    this.getHouseVideoList();
  },
};
</script>
<style lang="less" scoped>
.building-video {
  .video-top {
    width: 100%;
    height: 200px;
    position: relative;
    .top-icon {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background: rgba(0, 0, 0, 0.3);
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 12px;
      top: 12px;
      .van-icon {
        color: #e5e4e2;
        font-size: 20px;
      }
    }
    .top-name {
      background: rgba(0, 0, 0, 0.4);
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 40px;
      text-align: center;
      line-height: 40px;
      color: #fff;
      font-size: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 0 10px;
      word-break: break-all;
      box-sizing: border-box;
    }
    .top-img {
      img {
        width: 100%;
        height: 200px;
      }
    }
  }
  .video-content {
    .video-box {
      padding: 0 12px 5px;
      margin-top: 12px;
      background-color: #fff;
      .video-category {
        color: #444;
        font-size: 15px;
        line-height: 45px;
      }
      .video-msg {
        display: flex;
        align-items: center;
        width: 100%;
        overflow-x: auto;
        &::-webkit-scrollbar {
          display: none;
        }
        .video-item {
          display: flex;
          flex-direction: column;
          margin-right: 10px;
          &:last-child {
            margin-right: 0;
          }
          img {
            width: 160px;
            height: 90px;
          }
          span {
            color: #333;
            font-size: 13px;
            display: block;
            width: 120px;
            line-height: 30px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .video-text {
            height: 50px;
            p {
              color: #858585;
              font-size: 12px;
              line-height: 1;
            }
          }
        }
      }
    }
  }
}
</style>